import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from 'uuid';
import { AWS_URL } from "../../../Constant";
import { Link } from "react-router-dom";

const columns = [
  {
    field: "RetailStoreCode",
    headerName: "StoreName",
    width: 120,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "EmpId",
    headerName: "EmpId",
    width: 150,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "customerName",
    headerName: "customerNames",
    width: 170,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "customerNumber",
    headerName: "customerNumber",
    width: 160,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "productName",
    headerName: "productName",
    type: "number",
    width: 220,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "brand",
    headerName: "brand",
    width: 120,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "barcode",
    headerName: "barcode",
    width: 110,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "model_number",
    headerName: "Model_number",
    width: 170,
    headerClassName: "custom-header",
    headerAlign: "center",
  },

  {
    field: "quantity",
    headerName: "quantity",
    type: "number",
    width: 100,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "gst",
    headerName: "gst",
    type: "number",
    width: 100,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "subtotal",
    headerName: "subtotal",
    type: "number",
    width: 150,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
];


const SalesFilter = () => {
  const [storeInfo, setStoreInfo] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterProduct, setFilterProductList] = useState([]);
console.log("allp", allProducts)

  const flattenData = (products) => {
    return products.reduce((acc, item) => {
      item.datas.forEach((stock) => {
        acc.push({
          customerName: item.customerName,
          customerAddress: item.customerAddress,
          customerNumber: item.customerNumber,
          EmpId: item.EmpId,
          date: item.date,
          paymentMode: item.paymentMode,
          transactionId: item.transactionId,
          BillNo: item.BillNo,
          totalAmtWithoutGst: item.totalAmtWithoutGst,
          gst: item.gst,
          subtotal: item.subtotal,
          barcode: stock.barcode,
          brand: stock.brand,
          model_number: stock.model_number,
          productName: stock.productName,
          serialNumber: stock.serialNumber,
          quantity: stock.quantity,
          mrp1: stock.mrp1,
          landingCost: stock.landingCost,
          discount: stock.discount,
          cutoffprice: stock.cutoffprice,
          RetailStoreCode: item.RetailStoreCode || "Unknown",
          _id: stock._id || uuidv4(),
        });
      });
      return acc;
    }, []);
  };

  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");
    const url = `${AWS_URL}/getAllStoreData`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (response.status === 200) {
        const data = await response.json();
        setStoreInfo(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  
  // Process data and flatten it after fetching
  useEffect(() => {
    if (Array.isArray(storeInfo) && storeInfo.length > 0) {
      const combinedProducts = storeInfo.reduce((acc, store) => {
        const storeProducts = store.Sales.map((product) => ({
          ...product,
          RetailStoreCode: store.RetailStoreCode,
          _id: product._id || uuidv4(),
        }));
        return [...acc, ...storeProducts];
      }, []);

      setAllProducts(combinedProducts);
    }
  }, [storeInfo]);

  const handleFilterData = (event) => {
    setSearchQuery(event.target.value);
  };

  // Flatten and filter the data
  useEffect(() => {
    const allSalesStocks = flattenData(allProducts);
    console.log("flatten", allSalesStocks)
    const regex = new RegExp(searchQuery, "i");
    const filteredData = allSalesStocks.filter((product) => {
      return (
        regex.test(product.productName) ||
        regex.test(product.brand) ||
        regex.test(product.RetailStoreCode)||
        regex.test(product.model_number)||
        regex.test(product.EmpId)

      );
    });

    setFilterProductList(filteredData);
  }, [allProducts, searchQuery]);





  return (
    <>
      <div className="gap-2 text-center mt-2 mr-2">
        <Link to={"/admin"}>
          <button className=" mr-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 rounded-lg w-40 text-white text-lg  ">
            Dashboard
          </button>
        </Link>
      </div>

      <div  className=" flex  justify-between pl-5 pr-5">
      <h1  className="font-bold text-lg underline mb-3"> Stocks   </h1>
      <div>
        <input 
            type="search"
            id="search"
            className="h-8 w-60  mt-2 mb-2 rounded-md border border-black bg-transparent  text-sm placeholder:text-gray-600"
            onChange={handleFilterData}
            placeholder="Search Products"
          />
      </div>
      </div>
            <div className="pl-5 pr-5" style={{ height: 500, width: "100%", overflow: "auto" }}>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: "primary.light",
                        "& .custom-header": {
                            backgroundColor: "primary.main",
                            color: "black",
                        },
                        "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                        },
                        "& .MuiDataGrid-sortIcon ": {
                            backgroundColor: "red",
                        },
                        ".MuiDataGrid-cell": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        },
                    }}
                    rows={filterProduct}
                    columns={columns}
                    getRowId={(row) => row._id}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 50 },
                        },
                    }}
                    pageSizeOptions={[5, 15]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </div>
    </>
  );
};

export default SalesFilter;
