import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
// import { Button } from '@mui/material';
import { FaEdit, FaTrash } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { AWS_URL } from "../../Constant";

const StoreSales = ({ storeSalesData, store_id }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterProductList, setFilterProductList] = useState([]);
  const [salesStocks, setSalesStocks] = useState([]);
  console.log("sales", salesStocks);

  const flattenData = (products) => {
    return products.reduce((acc, item) => {
      item.datas.forEach((stock) => {
        acc.push({
          customerName: item.customerName,
          customerAddress: item.customerAddress,
          customerNumber: item.customerNumber,
          EmpId: item.EmpId,
          date: item.date,
          paymentMode: item.paymentMode,
          transactionId: item.transactionId,
          BillNo: item.BillNo,
          totalAmtWithoutGst: item.totalAmtWithoutGst,
          gst: item.gst,
          subtotal: item.subtotal,
          barcode: stock.barcode,
          brand: stock.brand,
          model_number: stock.model_number,
          productName: stock.productName,
          serialNumber: stock.serialNumber,
          quantity: stock.quantity,
          mrp1: stock.mrp1,
          landingCost: stock.landingCost,
          discount: stock.discount,
          cutoffprice: stock.cutoffprice,
          _id: stock._id || uuidv4(),
        });
      });
      return acc;
    }, []);
  };

  useEffect(() => {
    const salesReport = storeSalesData["Sales"];
    const totalSalesStocks = salesReport.map((sales, idx) => ({
      ...sales,
      _id: sales._id || uuidv4(),
    }));
    setSalesStocks(totalSalesStocks);
    setFilterProductList(totalSalesStocks);
  }, [storeSalesData]);

  const handleDelete = (billNo) => {
    console.log(`Delete row with BillNo: ${billNo}`);
  };

  const columns = [
    {
      field: "EmpId",
      headerName: "EmpId",
      width: 150,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "customerName",
      headerName: "customerNames",
      width: 170,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "customerNumber",
      headerName: "customerNumber",
      width: 160,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "productName",
      headerName: "productName",
      type: "number",
      width: 220,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "brand",
      headerName: "brand",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "barcode",
      headerName: "barcode",
      width: 110,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "model_number",
      headerName: "Model_number",
      width: 170,
      headerClassName: "custom-header",
      headerAlign: "center",
    },

    {
      field: "quantity",
      headerName: "quantity",
      type: "number",
      width: 100,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "gst",
      headerName: "gst",
      type: "number",
      width: 100,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "subtotal",
      headerName: "subtotal",
      type: "number",
      width: 150,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "custom-header",
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit(params.row)}
        >
          <FaEdit />
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "custom-header",

      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.row.BillNo)}
        >
          <FaTrash />
        </Button>
      ),
    },
  ];

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditedProduct({ ...product });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedProduct(null);
    setEditedProduct(null);
  };

  const handleSaveEdit = async () => {
    try {
      const jwtToken = Cookies.get("jwt_token");
      // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/editProduct/${store_id}/${selectedProduct._id}`;
      const url = `${AWS_URL}/editProduct/${store_id}/${selectedProduct._id}`;

      const option = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(editedProduct),
      };
      const response = await fetch(url, option);
      if (response.ok) {
        console.log("Product updated successfully");
      } else {
        console.error("Failed to update product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleChange = (field, value) => {
    setEditedProduct((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleFilterData = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const allSalesStocks = flattenData(salesStocks);
    console.log("firstfla", allSalesStocks);

    const regex = new RegExp(searchQuery, "i");
    const filteredData = allSalesStocks.filter((product) => {
      return (
        regex.test(product.productName) ||
        regex.test(product.brand) ||
        regex.test(product.RetailStoreCode) ||
        regex.test(product.model_number) ||
        regex.test(product.EmpId)
      );
    });

    setFilterProductList(filteredData);
  }, [salesStocks, searchQuery]);

  return (
    <>
      <div className="flex  justify-between mb-2">
        <div>
          <h1 className="ml-10 text-lg font-semibold">
            State: {storeSalesData.state}
          </h1>
        </div>
        <div>
          <h1 className="text-xl font-bold mb-4 unde text-rose-600">
            Sales-Reports{" "}
          </h1>
        </div>

        <input
          type="search"
          id="search"
          className="h-8 w-60  mr-5 rounded-md border border-black bg-transparent  text-sm placeholder:text-gray-600"
          onChange={handleFilterData}
          placeholder="Search Sales data"
        />
      </div>

      <div style={{ height: 450, width: "100%", overflow: "auto" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .custom-header": {
              backgroundColor: "primary.main",
              color: "black",
            },
            "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-sortIcon ": {
              backgroundColor: "red",
            },
            ".MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          // rows={storeSalesData.Sales}
          rows={filterProductList}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[5, 15]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {editedProduct && (
            <>
              <TextField
                label="Product Name"
                value={editedProduct.productName}
                onChange={(e) => handleChange("productName", e.target.value)}
                fullWidth
              />
              {/* Add text fields for other fields as needed */}
              <TextField
                label="Brand"
                value={editedProduct.brand}
                onChange={(e) => handleChange("brand", e.target.value)}
                fullWidth
              />
              <TextField
                label="Model_number"
                value={editedProduct.model_number}
                onChange={(e) => handleChange("model_number", e.target.value)}
                fullWidth
              />
              {/* <TextField
                label="Barcode"
                value={editedProduct.barcode}
                onChange={(e) => handleChange("barcode", e.target.value)}
                fullWidth
              />
              <TextField
                label="Barcode"
                value={editedProduct.quantity}
                onChange={(e) => handleChange("quantity", e.target.value)}
                fullWidth
              /> */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StoreSales;
