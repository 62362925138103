import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from 'uuid';
import { AWS_URL } from "../../Constant";
import { Link } from "react-router-dom";

const columns = [

  {
    field: "RetailStoreCode",
    headerName: "StoreName",
    width: 150,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "productName",
    headerName: "ProductName",
    width: 250,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "brand",
    headerName: "Brands",
    width: 120,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "barcode",
    headerName: "Barcode",
    width: 160,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "model_number",
    headerName: "Model_number",
    width: 180,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "quantity",
    headerName: "Quantity",
    width: 100,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "landingCost",
    headerName: "LandingCost",
    width: 110,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "gst",
    headerName: "GST%",
    type: "number",
    width: 100,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
];

const Filter = () => {
  const [storeInfo, setStoreInfo] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("")
  const [filterProduct, setFilterProductList] = useState([])
 console.log("first", storeInfo)
 console.log("sec", allProducts)

  const handleFilterData = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const regex= new RegExp(searchQuery, "i")
    const filterData= allProducts.filter((product) => {
      return (
        regex.test(product.productName)||
        regex.test(product.brand)||
        regex.test(product.RetailStoreCode)
      )
    })
    setFilterProductList(filterData)
  }, [allProducts, searchQuery])
 
  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");
    // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/getAllStoreData`;
    const url = `${AWS_URL}/getAllStoreData`

    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      if (response.status === 200) {
        const data = await response.json();
        setStoreInfo(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if (Array.isArray(storeInfo) && storeInfo.length > 0) {
      const combinedProducts = storeInfo.reduce((acc, store) => {
        const storeProducts = store.Products.map((product, index) => ({
          ...product,
          RetailStoreCode: store.RetailStoreCode,
          _id: product._id || uuidv4(),
          // sno: index + 1,
        }));
        return [...acc, ...storeProducts];
      }, []);
      setAllProducts(combinedProducts);
      // console.log("Combined Products:", combinedProducts); 
    }
  }, [storeInfo]);


  return (
    <>
      <div className="gap-2 text-center mt-2 mr-2">
        <Link to={"/admin"}>
          <button className=" mr-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 rounded-lg w-40 text-white text-lg  ">
            Dashboard
          </button>
        </Link>
      </div>

      <div  className=" flex  justify-between pl-5 pr-5">
      <h1  className="font-bold text-lg underline mb-3"> Stocks   </h1>
      <div>
        <input 
            type="search"
            id="search"
            className="h-8 w-60  mt-2 mb-2 rounded-md border border-black bg-transparent  text-sm placeholder:text-gray-600"
            onChange={handleFilterData}
            placeholder="Search Products"
          />
      </div>
      </div>
      {/* {storeInfo &&
    storeInfo.map((store) => (
        <div key={store._id}> */}
            <div className="pl-5 pr-5" style={{ height: 500, width: "100%", overflow: "auto" }}>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: "primary.light",
                        "& .custom-header": {
                            backgroundColor: "primary.main",
                            color: "black",
                        },
                        "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                        },
                        "& .MuiDataGrid-sortIcon ": {
                            backgroundColor: "red",
                        },
                        ".MuiDataGrid-cell": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        },
                    }}
                    rows={filterProduct}
                    columns={columns}
                    getRowId={(row) => row._id}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    pageSizeOptions={[5, 15]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </div>
        {/* </div>
    ))
} */}

    </>
  );
};

export default Filter;
