import React, { useEffect, useState } from "react";
import { FaBook, FaDatabase, FaMoneyBillWave, FaStore } from "react-icons/fa";
import { Link } from "react-router-dom";
import image3 from "../Screenshot 2023-12-26 121640.png";
import { AiFillDashboard } from "react-icons/ai";
import Cookies from "js-cookie";
import Sidebar from "./Sidebar";
import { AWS_URL } from "../../Constant";
// import AdminDashboard from "../../Admin/AdminDashboard";

const Dashboard = () => {
  const [userData, setUserData] = useState("");
  
  // console.log("userdta", userData["storeCode"])
  const storeCode= userData["storeCode"]
  
  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");
    // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/userData`;
    const url = `${AWS_URL}/userData`
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      if (response.status === 200) {
        const data = await response.json();
        setUserData(data);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const checkUsertype = () => {
    if (userData && userData.usertype === "boss") {
      // console.log("this is the admin portal");
    } else if (userData && userData.usertype === "boss") {
      // console.log("this is the subadmin portal");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Sidebar>
        <div className="w-full   ">
          <div className="flex justify-center h-full ">
            <div>
              <div className="text-lg ">
                <h1 className=" text-blue-600 font-medium">
                  <span className=" text-rose-700  font-semibold">
                    Store Name:
                  </span>
                  {userData.storename}
                </h1>
                {/* <h1>Store_Code: {userData.storeCode}</h1> */}
              </div>
              <div className="flex text-lg bg-gray-300 w-40 p-2 ml-2 items-center mt-2 gap-2 rounded-xl justify-center">
                <AiFillDashboard className="text-[#2c4372]" />
                <h1 className=" cursor-pointer">Dashboard</h1>
              </div>
              <div className=" p-2 flex items-center justify-start flex-wrap gap-5 mt-4">
                <Link to={"/billing"}>
                  <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#f86d57] to-[#f12a5f]  shadow-lg w-[229px] shadow-gray-400 h-24">
                    <button className="text-xl font-semibold">Billing</button>
                    <FaMoneyBillWave size={64} className=" cursor-pointer" />
                  </div>
                </Link>
                <Link to={"/sales"}>
                  <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#49d6d1] to-[#08aace] shadow-lg w-[228px] shadow-gray-400 h-24">
                    <button className="text-xl font-semibold">
                      Sales Reports
                    </button>
                    <FaStore size={64} className=" cursor-pointer" />
                  </div>
                </Link>
                <Link to={"/product"}>
                  <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#519be0] to-[#2f3be7] shadow-lg w-[228px] shadow-gray-400 h-24">
                    <button className="text-xl font-semibold">
                      Add Products
                    </button>
                    <FaDatabase size={64} className=" cursor-pointer" />
                  </div>
                </Link>
                <Link to={"/stocks"}>
                  <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#c853df] to-[#5f12f8] shadow-lg w-[228px] shadow-gray-400 h-24">
                    <button className="text-xl font-semibold">Stocks</button>
                    <FaBook size={64} className=" cursor-pointer" />
                  </div>
                </Link>
                <Link to={"/stocksshort"}>
                  <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#727370] to-[#147b3b] shadow-lg w-[228px] shadow-gray-400 h-24">
                    <button className="text-xl font-semibold">
                      Shortage_Stocks
                    </button>
                    <FaDatabase size={64} className=" cursor-pointer" />
                  </div>
                </Link>

                {userData && userData.usertype === "boss" && (
                  <Link to={"/admin"}>
                    <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#effc5e] to-[#ea1405] shadow-lg w-[228px] shadow-gray-400 h-24">
                      <button
                        onClick={checkUsertype()}
                        className="text-xl font-semibold"
                      >
                        Admin Dashboard
                      </button>
                      <FaBook size={64} className=" cursor-pointer" />
                    </div>
                  </Link>
                )}

                {userData && userData.usertype === "subadmin" && (
                  <Link to={"/store"}>
                    <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#b0ae3f] to-[#db2938] shadow-lg w-[228px] shadow-gray-400 h-24">
                      <button
                        onClick={checkUsertype()}
                        className="text-xl font-semibold"
                      >
                        Store's Dashboard
                      </button>
                      <FaBook size={64} className=" cursor-pointer" />
                    </div>
                  </Link>
                )}
              </div>
              <div className="flex   ">
                <div className="sm:w-full xs:hidden sm:block ">
                  <img
                    src={image3}
                    className=" sm:w-4/5  md:w-5/6   h-[200px] ml-5 mt-5"
                    alt=""
                  />
                </div>

                <div className="mt-5 mr-10 flex flex-col justify-around  sm:w-1/2 md:w-[500px] xs:hidden sm:block  ">
                  <h1 className="text-start text-base ">Create New Billing</h1>
                  <div className="w-80 h-4 bg-rose-700 mb-2"></div>
                  <h1 className="text-start">Add New Stocks</h1>
                  <div className="w-80 h-4 bg-blue-600 mb-2"></div>
                  <h1 className="text-start">Sales Report</h1>
                  <div className="w-80 h-4 bg-orange-600 mb-2"></div>
                  <h1 className="text-start">Stocks Information</h1>
                  <div className="w-80 h-4 bg-green-700"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default Dashboard;
